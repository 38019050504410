<template>
    <v-card elevation="4" :color="color" :dark="dark" width="100%">
        <v-card-subtitle>
            <v-container>
                <v-row class="mb-n13">
                    <v-col cols="10"><p class="text-subtitle">{{title}}</p></v-col>
                    <v-col cols="2" align="end" justify="end"><v-icon  size="35">{{icon}}</v-icon></v-col>
                </v-row>
            </v-container>
        </v-card-subtitle>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12"><p class="text-h2">{{value}}</p></v-col>
                    <!--<v-col cols="5" align="end" justify="end" :class="getColor(indicator)+'--text'"><v-icon :color="getColor(indicator)">{{this.getIcon(indicator)}}</v-icon>{{indicator}}%</v-col>-->
                </v-row>
            </v-container>
        </v-card-text>
        
    </v-card>
</template>

<script>
export default {
    name: "IndicatorDashboardCard",
    props: {
        title: String,
        icon: String,
        value: Number,
        indicator: Number,
        color: String,
        dark: Boolean
    },
    methods: {
        getIcon(indicator){
            return indicator>0?'mdi-menu-up':'mdi-menu-down'
        },
        getColor(indicator){
            return indicator>0?'green':'red'
        }
    }
}
</script>

<style>

</style>