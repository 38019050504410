<template>
  <div>
    <v-container fluid>
      <v-row justify="center" align="center" style="width:100%">
        <v-col cols="12" sm="6" md="6" lg="2" xsm="12"
          ><IndicatorDashboardCard
            :dark="true"
            title="Usuarios con licencia"
            icon="mdi-badge-account-horizontal"
            :value="statistics.user_bind_bill"
            :indicator="2.4"
            color="deep-purple darken-1"
        /></v-col>
        <v-col cols="12" sm="6" md="6" lg="2" xsm="12"
          ><IndicatorDashboardCard
            :dark="true"
            title="Licencias activas"
            icon="mdi-card-account-details-outline"
            :value="statistics.bill_account"
            :indicator="-2.4"
            color="indigo"
        /></v-col>
        <v-col cols="12" sm="6" md="6" lg="2" xsm="12"
          ><IndicatorDashboardCard
            :dark="true"
            title="Total de usuarios"
            icon="mdi-account-group"
            :value="statistics.user_total"
            :indicator="-2.4"
            color="primary"
        /></v-col>
        <v-col cols="12" sm="6" md="6" lg="2" xsm="12"
          ><IndicatorDashboardCard
            :dark="true"
            title="Usuarios en línea"
            icon="mdi-account"
            :value="statistics.user_online"
            :indicator="2.4"
            color="light-blue"
        /></v-col>
        <v-col cols="12" sm="6" md="6" lg="2" xsm="12"
          ><IndicatorDashboardCard
            :dark="true"
            title="Licencias por vencer -15d"
            icon="mdi-alert"
            :value="countToExpireBill"
            :indicator="2.4"
            color="red lighten-1"
        /></v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="6" xsm="12">
          <p class="text-subtitle">
            Historico de grabaciones por grupo <v-divider></v-divider>
          </p>
          <StandardTable
            :headers="dashboardGroupTable.headers"
            :items="groupAudioStatistics"
            itemKey="groupId"
            class="elevation-1"
            :loading="dashboardGroupTable.loading"
            loading-text="Cargando..."
            no-data-text="No hay datos disponibles"
            :dense="true"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6" xsm="12">
          <p class="text-subtitle">
            Top grupos con mayor número de PTTs <v-divider></v-divider>
          </p>
          <DoughnutChart
            ref="group_charts"
            :chart-data="groupDataset"
            :options="options"
          />
        </v-col>
        <v-col cols="12" sm="12" md="12" xsm="12">
          <p class="text-subtitle">
            Total de PTTs por grupo <v-divider></v-divider>
          </p>
          <StandardTable
            :headers="dashboardGroupTableTotal.headers"
            :items="totalGroupsPtt"
            itemKey="groupId"
            class="elevation-1"
            :loading="dashboardGroupTableTotal.loading"
            loading-text="Cargando..."
            no-data-text="No hay datos disponibles"
            :dense="true"
          />
        </v-col>
      </v-row>
      <!--
          <v-row>
            <v-col cols="12" sm="12" md="12" xsm="12">
              <p class="text-subtitle">Histograma de número de PTTs por grupo<v-divider></v-divider></p>
              <LineChart 
                ref="hist_group_chart"
                :chart-data="groupDataset"
                :options="options"
              />
            </v-col>
          </v-row>-->
      <!--<v-row>
            <v-col cols="12" sm="6" md="6" xsm="12">
                    <p class="text-subtitle">Top usuarios con mayor número de PTTs <v-divider></v-divider></p>
                    <DoughnutChart
                    ref="group_charts"
                    :chart-data="chartData"
                    :options="options"
                  />
              </v-col>
              <v-col cols="12" sm="12" md="12" xsm="12">
                  <p class="text-subtitle">Historico de grabaciones por usuario <v-divider></v-divider></p>
                  <StandardTable 
                    :headers="dashboardUserTable.headers" 
                    :items="userAudioStatistics"
                    itemKey="userAccount"
                    class="elevation-1"
                    :loading="dashboardUserTable.loading"
                    loading-text="Cargando..."
                    no-data-text="No hay datos disponibles"
                    :dense="true"
                    />
              </v-col>
          </v-row>-->
      <!--
          <v-row>
            <v-col cols="12" sm="12" md="12" xsm="12">
              <p class="text-subtitle">Histograma de número de PTTs por usuario<v-divider></v-divider></p>
              <LineChart 
                ref="hist_group_chart"
                :chart-data="chartData"
                :options="options"
              />
            </v-col>
          </v-row>-->
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IndicatorDashboardCard from "./StandardizedComponents/IndicatorDashboardCard";
import StandardTable from "./StandardizedComponents/standardTable.vue";
import DoughnutChart from "../components/Graphs/Doughnut.vue";
//import LineChart from '../components/Graphs/Line.vue'
import randomColor from "randomcolor";

const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: false,
  },
};

export default {
  data() {
    return {
      options,
      chartData: {
        labels: ["grupo1", "grupo2", "grupo3"],
        datasets: [
          {
            backgroundColor: [randomColor(), randomColor(), randomColor()],
            data: [100, 20, 30],
          },
        ],
      },
    };
  },
  components: {
    //LineChart,
    DoughnutChart,
    IndicatorDashboardCard,
    StandardTable,
  },
  computed: {
    ...mapGetters("Dashboard", ["groups"]),
    ...mapState("Dashboard", [
      "loading",
      "dashboardGroupTableTotal",
      "totalGroupsPtt",
      "statistics",
      "dashboardGroupTable",
      "groupAudioStatistics",
      "dashboardUserTable",
      "userAudioStatistics",
      "countToExpireBill"
    ]),
    groupDataset() {
      return {
        labels: this.groups.labels,
        datasets: this.groups.datasets,
      };
    },
    /*currentDataSet () {
        return this.chartData.datasets[0].data
      }*/
  },
  methods: {
    /*updateChart () {
        this.$refs.group_charts.update();
      },
      updateAmount (amount, index) {
        this.chartData.datasets[0].data.splice(index, 1, amount)
        this.updateChart();
      },
      updateName (text, index) {
        this.chartData.labels.splice(index, 1, text)
        this.updateChart();
      },
      addExperience() {
        const currentDataset = this.chartData.datasets[0]
        this.chartData.labels.push(`Skill ${currentDataset.data.length + 1}`)
        currentDataset.data.push(0)
        currentDataset.backgroundColor.push(randomColor())
        this.updateChart();
      },
      remove (index) {
        const currentDataset = this.chartData.datasets[0]
        currentDataset.data.splice(index, 1)
        this.chartData.labels.splice(index, 1)
        currentDataset.backgroundColor.splice(index, 1)
        this.updateChart()
      }*/
  },
};
</script>

<style>
</style>