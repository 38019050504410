<template>
  <div>
    
    <Navbar/>
    
    <Dashboard/>
    
    <!--<h1 style="text-align:center;">Próximamente.</h1>-->
  </div>
</template>

<script> 

  import Navbar from '../components/Navbar'
  import Dashboard from '../components/dashboard'
  export default {
    name: 'Home',
    components: {
      Navbar,
      Dashboard
    },
    created(){
        this.$store.dispatch("Dashboard/getStatistics")
        this.$store.dispatch('Users/getUsers')
    }
  }
</script>
