<template>
  <div>
      <v-data-table
        :headers="headers"
        :items="items"
        :class="classProp"
        :loading="loading"
        :loading-text="loadingText"
        :no-data-text="noDataText"
        :dense="dense"
      >
      </v-data-table>
      <!--<v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" v-for="item in headers" :key="item.value">{{item.text}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.groupId">
              <td>{{ item.groupId }}</td>
              <td>{{ item.create_time }}</td>
              <td>{{ item.end_time }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>-->
  </div>
</template>
<script>
export default {
  props: {
    headers: Array,
    items: Array,
    itemKey: String,
    classProp: String,
    loading: Boolean,
    loadingText: String,
    noDataText: String,
    dense: Boolean,
  },
};
</script>

<style>
</style>